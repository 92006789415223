import selfie from './self.jpg';
import './App.css';

const user = {
 name: 'Van Nguyen',
 imageUrl: selfie,
 imageSize: 100,
};

function App() {
 return (
   <div className="App">
     <header className="App-header">
       <h1>{user.name}</h1>
       <img
         className="avatar"
         src={user.imageUrl}
         alt={'Photo of ' + user.name}
         style={{
           width: user.imageSize,
           height: user.imageSize
         }}
       />
       
       <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white">
         <div className="max-w-6xl mx-auto px-4 py-20">
           <h1 className="text-4xl md:text-6xl font-bold mb-4">
             Where Code Meets Wanderlust
           </h1>

           <p className="text-xl mb-8">
             Full Stack .NET Developer | Travel Enthusiast | React Specialist
           </p>

           <div className="flex flex-wrap gap-4">
             {['.NET ', 'React ', 'Azure ', 'SQL Server ', 'TypeScript '].map((tech, index) => (
               <span key={index} className="px-4 py-2 bg-white/20 rounded-full text-sm">
                 {tech}
               </span>
             ))}
           </div>
         </div>
       <nav>

       <a className="navlinks" href="https://www.linkedin.com/in/van-nguyen1/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
       <a href="/departures.html">Departure Screen Project</a>
       </nav>
       </div>

     </header>
   </div>
 );
}

export default App;